export const templateScrum =
{
    "issueStatus": [
        {
            "id": "1",
            "name": "Backlog"
        },
        {
            "id": "2",
            "name": "To do"
        },
        {
            "id": "3",
            "name": "In progress"
        },
        {
            "id": "4",
            "name": "Done"
        }
    ],
    "issueType": {
        "0": {
            "color": "#00c982",
            "name": "Task",
            "icon": "check2-square",
            "id": "type1"
        },
        "1": {
            "icon": "bug",
            "name": "Bug",
            "color": "#e84b3d",
            "id": "type2"
        },
        "2": {
            "color": "#f4c537",
            "name": "Story",
            "id": "type3",
            "icon": "card-text",
        },
        "3": {
            "color": "#8859c6",
            "id": "type4",
            "name": "Epic",
            "icon": "diagram-3",
        },
    },
    "board": {
        "rice": false,
        "doneColumn": "4",
        "columnHeaderBadge": "itemsnumber"
    }
}