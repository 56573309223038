export const templateProjectManagement =
    {
        "issueStatus": [
            {
                "id": "1",
                "name": "Parking"
            },
            {
                "id": "2",
                "name": "To do"
            },
            {
                "id": "3",
                "name": "In progress"
            },
            {
                "id": "4",
                "name": "Done"
            }
        ],
        "issueType": {
            "0": {
                "color": "#00c982",
                "name": "Task",
                "icon": "check2-square",
                "id": "type1"
            },
        },
        "board": {
            "rice": false,
            "doneColumn": "4",
            "columnHeaderBadge": "itemsnumber"
        }
    }